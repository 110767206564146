import { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuscultation } from 'hooks/useAuscultation';
import { beginAuscultation, getOngoingAuscultation, lookupUser, getAuscultationAssessment } from 'api';
import PersonalIdentityNumberVerification from 'components/PersonalIdentityNumberVerification';
import Button from 'components/Button';
import Modal from 'components/Modal';
import styles from './HeartLungAuscultationIndex.module.scss';
import SetupSteps from '../SetupSteps';
import StatusMessage from 'components/StatusMessage';
import Mixpanel from 'services/tracking';
import { AuscultationSession, Patient } from 'providers/AuscultationProvider';
import RequestMicrophoneAccess from '../RequestMicrophoneAccess';
import { useTranslation } from 'react-i18next';
import { useBackLink } from 'pages/Index';
import AudioInputVerification from '../AudioInputVerification';
import AudioInputList from 'components/AudioInputList';
import { useAuth } from 'hooks/useAuth';
import HeadphoneSetup from '../HeadphoneSetup';
import {
  AUSCULTATION_ONBOARDING_STATUS,
  HEART_RECORDING_LOCATIONS,
  LUNG_RECORDING_LOCATIONS
} from '../../../constants';
import { getAuscultationSession, getRequirementIds } from 'utils';
import EcgUploadForm from 'components/EcgUploadForm';
import FadeoutMessage from 'components/FadeoutMessage';

type AuscultationMetadata = {
  numHeartAuscultations?: number;
  numLungAuscultations?: number;
  fileId?: string;
};

export default function HeartLungAuscultationIndex() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [setupModalActive, setSetupModalActive] = useState(false);
  const {
    setPatient,
    setAuscultationId,
    patient,
    mediaError,
    setDemoMode,
    demoMode,
    isFirstTimeSetup,
    loadAuscultationSession,
    nextSetupStepEnabled,
    clearRecordingData,
    setRequirementIds,
    clearLocalData
  } = useAuscultation();
  const [personalNumber, setPersonalNumber] = useState<string>();
  const form = useRef<HTMLFormElement>(null);
  const resetRef = useRef<Function>(null);
  const [patientNameError, setPatientNameError] = useState<unknown>();
  const [startingAuscultation, setStartingAuscultation] = useState(false);
  const [startAuscultationError, setStartAuscultationError] = useState<unknown>();
  const [ongoingAuscultation, setOngoingAuscultation] = useState<AuscultationSession>();
  const [ongoingAuscultationMetadata, setOngoingAuscultationMetadata] = useState<AuscultationMetadata>();
  const [noAssessmentError, setNoAssessmentError] = useState(false);
  const [ecgModalActive, setEcgModalActive] = useState(false);
  const [showUploadSuccess, setShowUploadSuccess] = useState(false);
  const { session } = useAuth();
  const location = useLocation();
  const { backLink } = useBackLink();
  const [currentSetupStep, setCurrentSetupStep] = useState(1);

  const setupSteps = [<AudioInputList />, <HeadphoneSetup />, <AudioInputVerification />];

  if (isFirstTimeSetup) {
    setupSteps.unshift(<RequestMicrophoneAccess />);
  }

  //@ts-expect-error
  const prefilledPersonalNumber = location?.state?.prefilledPersonalNumber;

  useEffect(() => {
    // Clear away any data stored in location state
    window.history.replaceState({}, document.title);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDemoMode(false);

    if (patientNameError) {
      return;
    }

    const formData = new FormData(e.target);
    const personalNumber = formData.get('personalIdentityNumber')?.toString();

    if (personalNumber) {
      setSetupModalActive(true);
      setPersonalNumber(personalNumber);
    }
  };

  const getFlowLandingPage = () => {
    if (
      !ongoingAuscultationMetadata ||
      ongoingAuscultationMetadata?.numHeartAuscultations !== HEART_RECORDING_LOCATIONS.length
    ) {
      return 'flow/heart';
    }

    if (ongoingAuscultationMetadata.numLungAuscultations !== LUNG_RECORDING_LOCATIONS.length) {
      return 'flow/lungs';
    }

    if (!ongoingAuscultationMetadata.fileId) {
      return 'flow/ekg';
    }

    return 'flow/summary';
  };

  const startAuscultationFlow = async () => {
    if (demoMode) {
      navigate('flow/heart');
      return;
    }

    try {
      setStartingAuscultation(true);
      await clearRecordingData();

      if (personalNumber && patient && patient.id) {
        setPatient({ ...patient, personalNumber: personalNumber.replace('-', '') });
        let auscultationId;

        if (ongoingAuscultation?.id) {
          auscultationId = ongoingAuscultation.id;
          await loadAuscultationSession(ongoingAuscultation);
        } else {
          // Todo: behöver nåt hända här för nextgen?
          const auscultation = await beginAuscultation(patient.id);
          auscultationId = auscultation.id;
        }

        await setAuscultationId(auscultationId);

        setPatient(patient, true);

        Mixpanel.track('Flow|Begin|Auscultation', { isNextGen: !!patient.nextGeneration });
        Mixpanel.timeEvent('Flow|Finish|Auscultation');

        navigate(getFlowLandingPage());
      } else {
        throw new Error('Patient ID missing');
      }
    } catch (error) {
      setStartingAuscultation(false);
      setStartAuscultationError(error);
    }
  };

  const startDemoAuscultation = (e) => {
    e.preventDefault();
    setDemoMode(true);
    setSetupModalActive(true);
    Mixpanel.track('Action|StartAuscultationDemoMode');
  };

  const getAssessment = async (patient: Patient) => {
    if (!patient.id) {
      return;
    }

    setPatient(patient);
    try {
      const response = await getAuscultationAssessment(patient.id);
      const auscultationAssessment: Assessment = await response.json();

      if (!auscultationAssessment) {
        setNoAssessmentError(true);
        return;
      }

      setNoAssessmentError(false);

      const requirementIds = getRequirementIds(auscultationAssessment);
      setRequirementIds(requirementIds);
      const ongoingSession = getAuscultationSession(auscultationAssessment);

      await setAuscultationId(auscultationAssessment.id.toString());

      setOngoingAuscultation(ongoingSession);
      setOngoingAuscultationMetadata({
        numHeartAuscultations: ongoingSession.recordings?.filter((r) => r.key.includes('heart')).length,
        numLungAuscultations: ongoingSession.recordings?.filter((r) => r.key.includes('lungs')).length,
        fileId: ongoingSession.fileId
      });
    } catch (error) {
      setNoAssessmentError(true);
      console.log(error);
    }
  };

  const lookupAuscultation = async (patient: Patient) => {
    setPatient(patient);

    try {
      if (patient.id) {
        const response = await getOngoingAuscultation(patient.id);
        if (!response.ok) {
          setOngoingAuscultation(undefined);
          setOngoingAuscultationMetadata(undefined);
          throw new Error(response.status === 404 ? 'No ongoing auscultation' : 'Could not fetch ongoing auscultation');
        }
        const auscultation = await response.json();
        setOngoingAuscultation(auscultation);
        setOngoingAuscultationMetadata({
          numHeartAuscultations: auscultation.recordings?.filter((r) => r.key.includes('heart')).length,
          numLungAuscultations: auscultation.recordings?.filter((r) => r.key.includes('lungs')).length,
          fileId: auscultation.fileId
        });
      }
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const closeSetupModal = () => {
    setSetupModalActive(false);
    setStartAuscultationError(undefined);
  };

  const clearOngoingAuscultation = () => {
    setOngoingAuscultationMetadata(undefined);
    setOngoingAuscultation(undefined);
  };

  const startAuscultationTestRecordingFlow = async () => {
    if (!session?.user?.guid) {
      return;
    }

    try {
      setStartingAuscultation(true);
      await clearLocalData();

      const auscultation = await beginAuscultation(session?.user?.guid);
      await setAuscultationId(auscultation.id);

      Mixpanel.track('Flow|Begin|TestAuscultation');
      Mixpanel.timeEvent('Flow|Finish|TestAuscultation');
      navigate('flow/heart');
    } catch (error) {
      setStartingAuscultation(false);
      setStartAuscultationError(error);
    }
  };

  const getSetupModalTitle = () => {
    switch (currentSetupStep) {
      case 1:
        return setupSteps.length === 4 ? t('page.auscultation.setup.step_0') : t('page.auscultation.setup.step_1');
      case 2:
        return setupSteps.length === 4 ? t('page.auscultation.setup.step_1') : t('page.auscultation.setup.step_2');
      case 3:
        return setupSteps.length === 4 ? t('page.auscultation.setup.step_2') : t('page.auscultation.setup.step_3');
      case 4:
        return t('page.auscultation.setup.step_3');
      default:
        return t('page.auscultation.setup.step_0');
    }
  };
  const handleLookupResponse = (patient: Patient) => {
    if (patient.nextGeneration) {
      getAssessment(patient);
    } else {
      lookupAuscultation(patient);
    }
  };

  const openEcgModal = async (e) => {
    e.preventDefault();
    setShowUploadSuccess(false);
    setDemoMode(false);

    if (!ongoingAuscultation?.id) {
      return;
    }

    await setAuscultationId(ongoingAuscultation.id);

    setEcgModalActive(true);
  };

  const getMainButtonLabel = () => {
    if (!ongoingAuscultation) {
      return 'Starta datainsamling';
    }

    if (
      ongoingAuscultation.recordings &&
      ongoingAuscultation.recordings.length > 0 &&
      ongoingAuscultation.recordings.length < HEART_RECORDING_LOCATIONS.length + LUNG_RECORDING_LOCATIONS.length
    ) {
      return 'Återuppta datainsamling';
    }

    if (
      ongoingAuscultation.recordings &&
      ongoingAuscultation.recordings.length === HEART_RECORDING_LOCATIONS.length + LUNG_RECORDING_LOCATIONS.length &&
      !ongoingAuscultation.comment
    ) {
      return 'Slutför datainsamling';
    }

    return 'Starta datainsamling';
  };

  // For nextGen, check that there exists at least one recording before allowing EKG upload directly from this page.
  const canUploadEkg =
    (!patient?.nextGeneration && ongoingAuscultation?.id) ||
    (patient?.nextGeneration &&
      (ongoingAuscultationMetadata?.numHeartAuscultations || ongoingAuscultationMetadata?.numLungAuscultations));

  return (
    <>
      <div className="narrow-page">
        {backLink}
        <h1>
          {t(
            `page.auscultation.header.${
              session?.user?.properties?.auscultationOnboarding === AUSCULTATION_ONBOARDING_STATUS.ACCEPTED
                ? 'accepted'
                : 'non_accepted'
            }`
          )}
        </h1>
        <p>
          {t(
            `page.auscultation.description.${
              session?.user?.properties?.auscultationOnboarding === AUSCULTATION_ONBOARDING_STATUS.ACCEPTED
                ? 'accepted'
                : 'non_accepted'
            }`
          )}
        </p>
        {session?.user?.properties?.auscultationOnboarding === AUSCULTATION_ONBOARDING_STATUS.ACCEPTED ? (
          <form onSubmit={handleSubmit} ref={form}>
            <PersonalIdentityNumberVerification
              onReset={resetRef}
              onError={(error: unknown) => {
                setPatientNameError(error);
                setOngoingAuscultation(undefined);
                setOngoingAuscultationMetadata(undefined);
              }}
              apiMethod={lookupUser}
              onApiResponse={handleLookupResponse}
              onInvalidInput={clearOngoingAuscultation}
              prefilledPersonalNumber={prefilledPersonalNumber}
            />

            <div className={styles.steps}>
              <h4>Utredningsmoment</h4>
              <ol>
                <li>
                  <span>Hjärtauskultation</span>
                  <span
                    className={`${styles.currentAuscultationStatus} ${
                      ongoingAuscultationMetadata?.numHeartAuscultations === HEART_RECORDING_LOCATIONS.length
                        ? styles.auscultationStepCompleted
                        : ''
                    }`}
                  >
                    {ongoingAuscultationMetadata?.numHeartAuscultations ?? 0} av {HEART_RECORDING_LOCATIONS.length}{' '}
                    inspelningar
                  </span>
                </li>
                <li>
                  <span>Lungauskultation</span>
                  <span
                    className={`${styles.currentAuscultationStatus} ${
                      ongoingAuscultationMetadata?.numLungAuscultations === LUNG_RECORDING_LOCATIONS.length
                        ? styles.auscultationStepCompleted
                        : ''
                    }`}
                  >
                    {ongoingAuscultationMetadata?.numLungAuscultations ?? 0} av {LUNG_RECORDING_LOCATIONS.length}{' '}
                    inspelningar
                  </span>
                </li>
                <li>
                  <span>EKG-uppladdning</span>
                  <span
                    className={`${styles.currentAuscultationStatus} ${
                      ongoingAuscultationMetadata?.fileId ? styles.auscultationStepCompleted : ''
                    }`}
                  >
                    {ongoingAuscultationMetadata?.fileId ? (
                      <>
                        <Button variant="text" onClick={openEcgModal}>
                          Ersätt fil
                        </Button>
                        <span>Uppladdad</span>
                      </>
                    ) : canUploadEkg ? (
                      <Button variant="text" onClick={openEcgModal}>
                        Ladda upp EKG
                      </Button>
                    ) : null}
                  </span>
                </li>
              </ol>
            </div>
            {noAssessmentError ? (
              <StatusMessage status="error">Det går inte att starta fysisk utredning av denna patient.</StatusMessage>
            ) : null}
            <Button
              type="submit"
              fullWidth={true}
              isLoading={startingAuscultation}
              disabled={
                noAssessmentError ||
                (ongoingAuscultationMetadata?.numHeartAuscultations === HEART_RECORDING_LOCATIONS.length &&
                  ongoingAuscultationMetadata.numLungAuscultations === LUNG_RECORDING_LOCATIONS.length &&
                  typeof ongoingAuscultation?.comment === 'string')
              }
            >
              {getMainButtonLabel()}
            </Button>
            <div className="text-center mt-1 fs-14">
              <Button variant="text" onClick={startDemoAuscultation}>
                Starta demoinspelning
              </Button>
            </div>
          </form>
        ) : (
          <>
            <div className="mb-2">
              <p>
                <strong>Hur går det till?</strong>
              </p>
              <ul className="disc-list">
                <li>
                  Provinspelningen består av samma moment som en vanlig utredning med digitalt stetoskop förutom att du
                  inte behöver ladda upp en EKG-fil.
                </li>
                <li>Du behöver genomföra provinspelningen på en testpatient, exempelvis en kollega.</li>
                <li>
                  Efter att du genomfört din provinspelning kommer vårdperonal hos Blodtrycksdoktorn att granska den.
                </li>
              </ul>
            </div>
            <div className="mb-2">
              <p>
                <strong>Vad händer sen?</strong>
              </p>
              <ul className="disc-list">
                <li>Inom några dagar kommer du att få återkoppling på provinspelningen via mejl.</li>
                <li>Om provinspelningen är godkänd kan du påbörja utredningar med patienter.</li>
                <li>Om provinspelningen inte är godkänd behöver du göra en ny provinspelning.</li>
              </ul>
            </div>
            {session?.user?.properties?.auscultationOnboarding === AUSCULTATION_ONBOARDING_STATUS.PENDING ? (
              <div className={styles.onboardingPending}>Inväntar bedömning av vårdpersonal hos Blodtrycksdoktorn.</div>
            ) : null}
            <Button
              fullWidth={true}
              isLoading={startingAuscultation}
              onClick={() => setSetupModalActive(true)}
              disabled={session?.user?.properties?.auscultationOnboarding === AUSCULTATION_ONBOARDING_STATUS.PENDING}
            >
              Starta provinspelning
            </Button>
          </>
        )}
      </div>
      <Modal isOpen={setupModalActive} onClose={closeSetupModal} size="large" title={getSetupModalTitle()} noPadding>
        <SetupSteps
          onFinish={
            session?.user?.properties?.auscultationOnboarding === AUSCULTATION_ONBOARDING_STATUS.ACCEPTED
              ? startAuscultationFlow
              : startAuscultationTestRecordingFlow
          }
          showStepCount={false}
          showSkip={false}
          displayInModal
          finishDisabled={!!mediaError}
          steps={setupSteps}
          nextStepEnabled={nextSetupStepEnabled}
          onStepChanged={(step) => setCurrentSetupStep(step)}
        />
        {startAuscultationError ? (
          <div className="mt-1">
            <StatusMessage status="error">
              Det gick inte att starta auskultationsflödet. Försök igen eller kontakta support.
            </StatusMessage>
          </div>
        ) : null}
      </Modal>
      <Modal
        isOpen={ecgModalActive}
        title="Ladda upp EKG"
        onClose={() => setEcgModalActive(false)}
        actionText="Klar"
        onAction={() => setEcgModalActive(false)}
      >
        <EcgUploadForm
          onSuccess={(filename: string) => {
            setOngoingAuscultationMetadata({ ...ongoingAuscultationMetadata, fileId: filename });
            setOngoingAuscultation({ ...ongoingAuscultation, fileId: filename });
            setShowUploadSuccess(true);
          }}
        />
        {showUploadSuccess ? (
          <FadeoutMessage>
            <StatusMessage>Den valda EKG-filen sparades.</StatusMessage>
          </FadeoutMessage>
        ) : null}
      </Modal>
    </>
  );
}
